'use client';

import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

interface SearchBarProps {
    placeholder: string;
    buttonText: string;
    searchUrl: string;
    className?: string;
    background?: string;
    titleColor?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
    placeholder,
    buttonText,
    searchUrl,
    className = '',
    background = "bg-secondary",
    titleColor = "text-black",
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPlaceholder, setCurrentPlaceholder] = useState('');
    const router = useRouter();

    useEffect(() => {
        // Array of example cancer conditions moved inside useEffect
        const placeholders = [
            "Breast Cancer",
            "Basal Cell Skin Cancer",
            "Melanoma",
            "Leukemia",
            "Lymphoma",
            "Ovarian Cancer",
            "Pancreatic Cancer"
        ];

        let currentIndex = 0;
        let currentChar = 0;
        let isDeleting = false;
        let timeoutId: NodeJS.Timeout;

        const animatePlaceholder = () => {
            const currentText = placeholders[currentIndex];

            if (isDeleting) {
                setCurrentPlaceholder(currentText.substring(0, currentChar));
                currentChar--;

                if (currentChar === 0) {
                    isDeleting = false;
                    currentIndex = (currentIndex + 1) % placeholders.length;
                }
            } else {
                setCurrentPlaceholder(currentText.substring(0, currentChar));
                currentChar++;

                if (currentChar > currentText.length) {
                    isDeleting = true;
                    // Pause at the end of the word
                    timeoutId = setTimeout(animatePlaceholder, 3000);
                    return;
                }
            }

            // Typing speed
            const speed = isDeleting ? 50 : 100;
            timeoutId = setTimeout(animatePlaceholder, speed);
        };

        animatePlaceholder();

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, []); // Empty dependency array since placeholders is now inside

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const encodedQuery = encodeURIComponent(searchQuery);
        const fullUrl = `${searchUrl}#query=${encodedQuery}`;
        router.push(fullUrl);
    };

    return (
        <div className={`rh-searchbar px-2 py-4 border-y-1 border-primary ${background} ${className}`}>
            <form onSubmit={handleSubmit} className="relative w-full mx-auto">
                <div className="container mx-auto relative">
                    <label
                        htmlFor="searchbar-input"
                        className="sr-only"
                    >
                        search query
                    </label>

                    <input
                        id="searchbar-input"
                        type="text"
                        placeholder={currentPlaceholder}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="rh-searchbar-input"
                    />

                    <button
                        type="submit"
                        className="rh-searchbar-btn"
                    >
                        {buttonText}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SearchBar;
