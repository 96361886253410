import log from "loglevel";

// Set default level to INFO
const defaultLevel = log.levels.INFO;

// Get log level from environment variable - check both backend and frontend env vars
const envLogLevel = (
  process.env.LOG_LEVEL || process.env.NEXT_PUBLIC_LOG_LEVEL
)?.toLowerCase();

// Use environment variable if valid, otherwise use default
const validLevels = [
  "trace",
  "debug",
  "info",
  "warn",
  "error",
  "silent",
] as const;
type LogLevel = (typeof validLevels)[number];

const logLevel =
  envLogLevel && validLevels.includes(envLogLevel as LogLevel)
    ? (envLogLevel as log.LogLevelDesc)
    : defaultLevel;

log.setLevel(logLevel);

// Check if we're running in a browser environment
const isBrowser = typeof window !== "undefined";

interface LogEntry {
  timestamp: string;
  message: string;
  module?: string;
  data?: any;
}

// Helper function to create structured log object
const createStructuredLog = (
  message: string,
  args: any[]
): string | [LogEntry] => {
  const timestamp = new Date().toISOString();

  // For browser console, return objects directly
  if (isBrowser) {
    // If using new format (message + {module, data})
    if (
      args.length === 1 &&
      typeof args[0] === "object" &&
      "module" in args[0]
    ) {
      const { module, data } = args[0];
      return [
        {
          timestamp,
          message,
          module,
          data,
        },
      ];
    }

    // For old format or other cases
    return [
      {
        timestamp,
        message,
        data: args.length === 1 ? args[0] : args.length > 1 ? args : undefined,
      },
    ];
  }

  // For server-side/CloudWatch, return JSON string
  const logObject =
    args.length === 1 && typeof args[0] === "object" && "module" in args[0]
      ? {
          timestamp,
          message,
          module: args[0].module,
          data: args[0].data,
        }
      : {
          timestamp,
          message,
          data:
            args.length === 1 ? args[0] : args.length > 1 ? args : undefined,
        };

  return JSON.stringify(logObject);
};

// Custom log methods that preserve object formatting in browser console
const logger = {
  info: (message: string, ...args: any[]) => {
    const logData = createStructuredLog(message, args);
    isBrowser ? log.info(...(logData as [LogEntry])) : log.info(logData);
  },
  warn: (message: string, ...args: any[]) => {
    const logData = createStructuredLog(message, args);
    isBrowser ? log.warn(...(logData as [LogEntry])) : log.warn(logData);
  },
  error: (message: string, ...args: any[]) => {
    const logData = createStructuredLog(message, args);
    isBrowser ? log.error(...(logData as [LogEntry])) : log.error(logData);
  },
  debug: (message: string, ...args: any[]) => {
    const logData = createStructuredLog(message, args);
    isBrowser ? log.debug(...(logData as [LogEntry])) : log.debug(logData);
  },
  setLevel: (level: log.LogLevelDesc) => log.setLevel(level),
};

export default logger;
