'use client';

import { useGlobalState } from '@/layers/presentation/context/GlobalStateContext';
import { useTranslation } from '@/layers/presentation/i18n/client';
import { UserType } from '@/types/modelEnums';
import { cn } from '@/utils/cn';
import {
  Button,
  Link,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Navbar as NextUINavbar
} from '@nextui-org/react';
import { LogIn, LogOut, User } from 'lucide-react';
import { useState } from 'react';
import Logo from './Logo';
import Menu from './Menu';

type NavbarVariant = 'default' | 'transparent' | 'dark';

interface NavbarProps {
  variant?: NavbarVariant;
}

const Navbar: React.FC<NavbarProps> = ({ variant = 'default' }) => {
  const { t } = useTranslation();
  const { user } = useGlobalState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const variantStyles = {
    default: "bg-white/80 backdrop-blur-[30px] !backdrop-grayscale-[0.5] border-b border-primary",
    transparent: "bg-transparent",
    dark: "bg-primary/80 backdrop-blur-[30px]"
  };

  const textStyles = {
    default: "text-primary",
    transparent: "text-primary",
    dark: "text-white"
  };

  const menuItems = [
    ...(user?.type === UserType.DOCTOR &&
      [{
        href: "/doctor",
        label: t('components:navbar.doctor.main'),
      },
      {
        href: "/doctor/programs",
        label: t('components:navbar.doctor.searchPrograms'),
      },
      {
        href: "/doctor/patients",
        label: t('components:navbar.doctor.patients'),
      }] || []
    ),
    ...(user?.type === UserType.SPONSOR &&
      [{
        href: "/sponsor",
        label: t('components:navbar.sponsor.main'),
      },
      {
        href: "/sponsor/programs",
        label: t('components:navbar.sponsor.myPrograms'),
      },
      {
        href: "/sponsor/programRequests",
        label: t('components:navbar.sponsor.programRequests'),
      },
      {
        href: "/sponsor/reports",
        label: t('components:navbar.sponsor.reports'),
      }] || []
    ),
    ...(!user &&
      [{
        href: "/about/patients",
        label: t('components:navbar.about.forPatients'),
      },
      {
        href: "/about/doctors",
        label: t('components:navbar.about.forDoctors'),
      },
      {
        href: "/about/sponsors",
        label: t('components:navbar.about.forSponsors'),
      },
      {
        href: "/about/contact-us",
        label: t('components:navbar.about.contactUs'),
      },
      {
        href: "/about/faq",
        label: t('components:navbar.about.faq'),
      }] || []
    ),
    {
      href: "/blog",
      label: t('components:navbar.blog'),
    },
  ];

  return (
    <NextUINavbar
      maxWidth="full"
      className={cn(
        "p-3 fixed top-0 left-0 right-0 z-50 px-0 sm:px-6",
        variantStyles[variant]
      )}
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
    >
      <NavbarContent className="gap-4">
        <NavbarBrand>
          <Logo
            className="flex"
            variant={variant}
          />
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex" justify="center">
        <Menu
          menuItems={menuItems}
          className={textStyles[variant]}
        />
      </NavbarContent>

      <NavbarContent justify="end">
        <NavbarItem key="login" className="hidden sm:flex">
          {user ? (
            <div className="flex flex-row gap-4 items-center">
              <Button
                as={Link}
                href="/users/profile"
                variant="bordered"
                startContent={<User size={18} />}
                className={cn(
                  "border-1",
                  textStyles[variant]
                )}
              >
                {t('components:navbar.myProfile')}
              </Button>
              <Button
                as={Link}
                href="/users/auth?logout=true"
                variant="bordered"
                startContent={<LogOut size={18} />}
                className={cn(
                  "border-1",
                  textStyles[variant]
                )}
              >
                Logout
              </Button>
            </div>
          ) : (
            <Button
              as={Link}
              variant="bordered"
              startContent={<LogIn size={18} />}
              className={cn(
                "border-1",
                variant === 'dark' && "text-white"
              )}
              href="/users/auth"
            >
              Login or Sign Up
            </Button>
          )}
        </NavbarItem>

        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className={cn(
            "sm:hidden",
            variant === 'dark' && "text-white"
          )}
        />
      </NavbarContent>

      <NavbarMenu className="pt-6">
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item.href}-${index}`}>
            <Link
              href={item.href}
              className="w-full text-lg py-2"
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          </NavbarMenuItem>
        ))}

        <NavbarMenuItem className="mt-6">
          {user ? (
            <>
              <Link
                href="/users/profile"
                className="w-full text-lg py-2"
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="flex items-center gap-2">
                  <User size={18} />
                  {t('components:navbar.myProfile')}
                </div>
              </Link>
              <Link
                href="/users/auth?logout=true"
                className="w-full text-lg py-2"
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="flex items-center gap-2">
                  <LogOut size={18} />
                  Logout
                </div>
              </Link>
            </>
          ) : (
            <Link
              href="/users/auth"
              className="w-full text-lg py-2"
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center gap-2">
                <LogIn size={18} />
                Login or Create an Account
              </div>
            </Link>
          )}
        </NavbarMenuItem>
      </NavbarMenu>
    </NextUINavbar>
  );
};

export default Navbar;
